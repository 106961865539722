<template>
  <div :class="$style.categories">
    <div :class="$style.wrapper">
      <div :class="$style.addCategory">
        <el-input
          :class="$style.addInput"
          v-model="newCategory"
          placeholder="Введите новую категорию..."
          class="input-with-select"
        >
          <el-button
            slot="append"
            icon="el-icon-plus"
            @click="createCategory()"
          />
        </el-input>
      </div>
    </div>

    <div :class="$style.responsive">
      <table :class="$style.tabel">
        <thead :class="$style.header">
          <tr :class="$style.row">
            <th :class="[$style.draggable, $style.nograb]"></th>
            <th :class="$style.orderField">№ п/п</th>
            <th :class="$style.name">Название категории</th>
          </tr>
        </thead>
        <Container
          tag="tbody"
          :class="$style.body"
          @drop="onDrop"
          @drag-start="drag = false"
          @drag-end="drag = true"
        >
          <Draggable
            tag="tr"
            :class="$style.row"
            v-for="item in categories"
            :key="item.id"
          >
            <td :class="$style.draggable">
              <Icon name="draggable" :class="$style.icon" />
            </td>
            <td :class="$style.orderField">
              {{ item.orderField }}
            </td>
            <td :class="$style.name">
              <el-input
                placeholder="Please input"
                v-model="item.name"
                class="input-with-select"
              >
                <el-button
                  slot="append"
                  icon="el-icon-edit"
                  @click="updateCategory(item)"
                ></el-button>
              </el-input>
            </td>
            <td>
              <ActionButtons
                :class="$style.buttons"
                @delete="removeCategory(item)"
              />
            </td>
          </Draggable>
        </Container>
      </table>
    </div>
  </div>
</template>

<script>
import delivery from '@/delivery'
import ActionButtons from '@/components/atoms/ActionsButtons.vue'
import Icon from '@/components/atoms/Icon'

export default {
  components: { ActionButtons, Icon },
  data() {
    return {
      newCategory: '',
      categories: [],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const loading = this.$loading({
        lock: true,
      })

      const query = {
        withFaqs: 'false',
        withPagination: 'false',
      }

      const { value, error } =
        await delivery.ContentServiceCore.FAQCategoriesActions.getList(query)
      if (error) return

      this.categories = value.data

      loading.close()
    },

    async createCategory() {
      const result =
        await delivery.ContentServiceCore.FAQCategoriesActions.create({
          name: this.newCategory,
        })

      if (!result.error) {
        this.$message({
          message: 'Категория успешно создана',
          type: 'success',
        })
        this.newCategory = ''
        this.getList()
      }
    },

    async save(list) {
      const loading = this.$loading({
        lock: true,
      })

      var orderField = 0
      var updateList = []

      list.forEach((item) => {
        orderField++
        if (item.orderField != orderField) {
          item.orderField = orderField
          updateList.push(item)
        }
      })

      const res = await delivery.ContentServiceCore.FAQCategoriesActions.update(
        updateList,
      )
      loading.close()
      if (res.error) return
    },

    async updateCategory(data) {
      const result =
        await delivery.ContentServiceCore.FAQCategoriesActions.updateByID(
          data.id,
          data,
        )

      if (!result.error) {
        this.$message({
          message: 'Категория успешно отредактирована',
          type: 'success',
        })
        this.getList()
      }
    },

    async removeCategory(data) {
      const isConfirm = confirm(`Вы точно хотите удалить ${data.name}?`)
      if (isConfirm) {
        const res =
          await delivery.ContentServiceCore.FAQCategoriesActions.delete(data.id)
        if (!res.error) {
          this.$message({
            message: 'Категория успешно удалена',
            type: 'success',
          })
          this.getList()
        }
      }
    },

    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr

      const result = [...arr]
      let itemToAdd = payload

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }

      return result
    },
    onDrop(dropResult) {
      document.body.classList.remove('smooth-dnd-no-user-select')
      document.body.classList.remove('smooth-dnd-disable-touch-action')
      this.categories = this.applyDrag(this.categories, dropResult)

      this.save(this.categories)
    },
  },
}
</script>

<style lang="scss" module>
.categories {
  padding-top: 1rem;
  overflow: hidden;

  .wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    align-items: center;
    .addCategory {
      display: flex;
      .addInput {
        width: 50rem;
        margin-right: 0.625rem;
      }
    }
  }

  h2 {
    padding: 1rem 0;
  }

  .responsive {
    border-radius: 0.25rem;
    @include tabelShadow;
    width: 100%;
    overflow-x: auto;
    .tabel {
      width: 100%;
      table-layout: auto;
      .header {
        th {
          color: $tabel-th-color;
          font-weight: 700;
          text-align: left;
        }
      }
      .row {
        display: block;
        padding: 0.5rem;
        border-bottom: 1px solid $tabel-border;
        &:nth-child(odd) {
          background-color: $bgc-tabel-row;
        }
        .draggable {
          min-width: 5rem;
          cursor: move;
          &.nograb {
            cursor: default;
          }
          .icon {
            width: 1rem;
            height: 1rem;
          }
        }
        .name {
          width: 90%;
          text-align: left;
        }
        .orderField {
          min-width: 10rem;
        }
      }
    }
  }
}
</style>
